























import { Component, Vue, Prop } from "vue-property-decorator";

export default class Preferential extends Vue {
    get PageTopHeight(){
        const { clientWidth, offsetWidth } = document.body;
        console.log(clientWidth,'clientWidth');
        return `height:${clientWidth*0.837}px`
    }
    created(){
    }
}
